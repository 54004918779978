<template>
  <div class="transaction">
    <header-page>
      <router-link :to="{name: 'stock.index'}">
        <span
          class="size14 fw-bold-700"
          style="color: #0B5FFF;margin-right: 5px;"
        >Pengaturan Stok</span>
      </router-link>
      <span
        class="text-dark text-darken-5"
        style="margin-right: 5px;"
      >></span>
      <span class="text-dark fw-bold-700 size14">Stok Gudang</span>
    </header-page>
    <div class="transaction__filter--container py-2 pl-0 pr-2">
      <b-button-group>
        <b-button class="filter--button active">
          Transfer Stok
        </b-button>
      </b-button-group>
      <div class="d-flex search__export align-items-center">
        <b-button variant="dark py-1" @click="$router.push({ name: 'stock.transfer_create' })">
          <span>Tambah Transfer Stok</span>
        </b-button>
      </div>
    </div>

    <keep-alive>
      <TableTransferGudang
        :get-data-product="getData"
        :result-product="result"
        :is-loading="isLoading"
        @filterIncomeProduct="getFilter"
      />
    </keep-alive>
    <!-- <ModalIncomeProduct :get-stock-warehouse="getData" /> -->
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BButton, BButtonGroup, VBToggle,
} from 'bootstrap-vue'
import moment from 'moment'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import HeaderPage from '@/components/HeaderPage.vue'
import TableTransferGudang from '@/components/Stock/TransferGudang/TableTransferGudang.vue'
// import ModalIncomeProduct from '@/components/Stock/Modal/ModalIncomeProduct.vue'

export default {
  title() {
    return 'Stok Lokasi SKU'
  },
  components: {
    BButton,
    HeaderPage,
    BButtonGroup,
    TableTransferGudang,
    // ModalIncomeProduct,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      comp: 'TableIncomeProduct',
      result: {},
      filters: {
        per_page: 10,
      },
      messages: '',
      isLoading: false,
      formPayload: this.$store.state.stockWarehouse.payloadRetail,
      isEdit: false,
    }
  },
  watch: {
    filters: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
    '$store.state.stockWarehouse.payloadRetail': {
      handler(value) {
        this.formPayload = value
      },
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getFilter(value) {
      this.filters = value
    },
    getFilterRetail(value) {
      this.filters = value
    },
    getFilterBase(value) {
      this.filters = value
    },
    submit() {
      this.$store.commit('stockWarehouse/setIsLoading', true)
      const form = this.preparePayload()
      this.$store.dispatch('stockWarehouse/postRetailProducts', {
        uuid: '',
        payload: form,
      }).then(() => {
        successNotification(this, 'Success', 'Stok gudang berhasil ditambahkan')
        this.getData()
        this.$bvModal.hide('modal-add-stock-eceran')
        this.$store.commit('stockWarehouse/setIsLoading', false)
        this.$store.commit('stockWarehouse/setPayloadRetail', {
          retail_product_uuid: '',
          date: moment(new Date()).format('YYYY-MM-DD'),
          warehouse_uuid: '',
          qty_parent: 0,
        })
        this.messages = ''
      }).catch(error => {
        this.$store.commit('stockWarehouse/setIsLoading', false)
        this.messages = error.response.data.meta.messages
        if (error.response.data.meta.messages.length > 0) {
          errorNotification(this, 'Error', error.response.data.meta.messages)
        }
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }
      return form
    },
    getFilterStockActual(value) {
      this.filters = {
        ...this.filters,
        per_page: value.per_page,
      }
    },
    async getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filters
      queryParams.page = page
      await this.$store.dispatch('stockWarehouse/getTransfer', {
        uuid: '',
        params: queryParams,
      }).then(result => {
        this.isLoading = false
        this.result = result.data.data
      }).catch(err => {
        this.isLoading = false
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/scss/variables/_variables.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

.transaction {
  position: relative;

  .vgt-table.bordered {
    thead {
      th:last-child {
        text-align: center;
      }
    }
    td {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      vertical-align: middle;
    }
    th {
      border: none;
      border-bottom: 1px solid #ebe9f1;
      color: #6B6C7E;
      font-weight: 700;
    }

  }

  .page-item {
    .page-link {
      font-weight: 600;
      color: $secondary-2 !important;
      background-color: transparent;
    }
    &.active {
      .page-link {
        border-radius: 0;
        background-color: $light--1;
        font-weight: 600;
        color: $secondary-2 !important;
      }
    }
  }

  .page-item.prev-item ~ .page-item:nth-child(2) .page-link {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }

  .page-item.prev-item ~ .page-item:nth-last-child(2) .page-link {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .page-item.next-item .page-link:active,
  .page-item.next-item .page-link:hover {
    background-color: transparent !important;
  }

  .pagination__container {
    width: 100%;
    height: 80px;
    position: fixed;
    bottom: 0;
    background: #FFFFFF;
    box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
    border: 1px solid #E4E5EC;

    .dropdown {

      button {
        font-size: 14px;
        color: $dark;
        font-weight: 600;

        &:hover {
          background-color: transparent;
        }
      }
    }

    small {
      color: $secondary-2;
      font-size: 14px;
      font-weight: 400;
    }
  }

  #dropdown-dropleft {
    .dropdown-toggle {
      border-color: transparent !important;
      background-color: transparent !important;
      color: $dark !important;

      &:hover {
        border-color: transparent !important;
        background-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
  .transaction__filter--container {
    display: flex;
    justify-content: space-between;

    .search__export {
      .input-group {
        width: 23.451vw;
        height: 48px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

        input {
          background: #fff;
          height: 48px;
          border: none;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }

        .input-group-append {
          .input-group-text {
            background-color: #fff !important;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            border: none;
          }
        }
      }
      #search {
        width: 278px;
        height: 48px;
        border: none;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

      }

      .btn.bg-white {
        border: none !important;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
        border-radius: 8px;

        img {
          margin-right: 8px;
        }

        span {
          color: $dark;
          font-size: 14px;
          font-weight: 800;
        }
      }
    }

    .filter--button {
      border: none !important;
      color: $light--2 !important;
      background-color: transparent !important;
      border-color: transparent !important;

      &.active {
        color: $secondary !important;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          bottom: -48%;
          left: 0;
          width: 50%;
          margin-left: 25%;
          border-radius: 4px 4px 0px 0px;
          border: 2px solid $secondary;
        }
      }

      &:hover {
        box-shadow: none !important;
      }
    }
  }
}
</style>
